(function () {
  const wgrnhText = document.getElementById('wgrnh-text');

  const possibleTexts = [
    'Nu',
    'Een half uur geleden',
    'Zo snel mogelijk',
    'Als het even kan nu',
  ];

  function setRandomText() {
    const idx = Math.round(Math.random() * (possibleTexts.length - 1));

    wgrnhText.innerText = possibleTexts[idx];
  }

  setRandomText();
  wgrnhText.addEventListener('click', setRandomText);
})();
